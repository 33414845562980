import React from 'react'
import { getLvpLayout } from '@chegg-wt/core/components/Layouts/LargeVideoPlayerLayout'
import { NextPageWithLayout } from '@chegg-wt/core/types/layout'
import { HomeTop } from '@chegg-wt/core/components/Layouts/Home'
import { useSelector } from 'react-redux'
import SEOTitle from '@chegg-wt/core/components/SEOTitle'
import WritingAssistantHome from '@chegg-wt/core/components/Layouts/WritingAssistantHome'
import { useOnUnloadTimeOnPage } from '@chegg/time-on-page'
import { isWritingAssistantHomeFlagEnabledSelector } from '@chegg-wt/core-wa/state/selectors'
import { cards } from '../copy/Home'
import resources from '../copy/resources'

const HomePage: NextPageWithLayout = () => {
  const isWritingAssistantHomeEnabled = useSelector(
    isWritingAssistantHomeFlagEnabledSelector
  )

  useOnUnloadTimeOnPage()
  return (
    <>
      <SEOTitle title="Citation Machine®: Format & Generate - APA, MLA, & Chicago" />
      {isWritingAssistantHomeEnabled !== null &&
        (isWritingAssistantHomeEnabled ? (
          <WritingAssistantHome copy={{ resources }} />
        ) : null)}
    </>
  )
}

HomePage.getPageLayout = (page) =>
  getLvpLayout(page, () => <HomeTop cards={cards} />, false, false, true)

export default HomePage
